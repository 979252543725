import YouTubePlayer from "youtube-player";
import { Context, Controller } from "@hotwired/stimulus";
import { Navigation, Mousewheel } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default class extends Controller {
  static targets = ["guide", "video"];
  declare readonly guideTarget: HTMLElement;
  declare readonly videoTargets: HTMLVideoElement[];
  swiper: Swiper;
  players: YouTubePlayer[];

  constructor(context: Context) {
    super(context);
    this.players = [];
  }

  connect(): void {
    const element = this.element as HTMLElement;
    this.swiper = new Swiper(element, {
      direction: "vertical",
      modules: [Navigation, Mousewheel],
      loop: false,
      mousewheel: {
        invert: false,
        thresholdDelta: 80,
      },
      on: {
        click: () => {
          this.currentVideo()
            .getPlayerState()
            .then((state) => {
              if (state === 1) {
                this.currentVideo().pauseVideo();
              } else {
                this.currentVideo().playVideo();
              }
            });
        },
        beforeTransitionStart: () => {
          this.stopAllVideo();
        },
        slideChangeTransitionEnd: () => {
          this.currentVideo().playVideo();
          gtag("event", "select_content", {
            content_type: "salon_selection",
            content_id: `swipe_${
              this.videoTargets[this.swiper.activeIndex].dataset.salonId
            }`,
          });
        },
      },
    });

    this.initializeVideos();
  }

  initializeVideos(): void {
    this.videoTargets.forEach((video, index) => {
      this.players.push(
        YouTubePlayer(video.id, {
          videoId: video.dataset.videoId,
          playerVars: {
            autoplay: index === 0 ? 1 : 0,
            loop: 1,
            mute: 1,
            playlist: video.dataset.videoId,
            controls: 0,
            playsinline: 1,
          },
        }),
      );
    });
  }

  currentVideo(): YouTubePlayer {
    return this.players[this.swiper.activeIndex];
  }

  async stopAllVideo(): Promise<void> {
    await Promise.all(this.players.map((player) => player.stopVideo()));
  }

  onTouchStart() {
    if (!this.guideTarget.classList.contains("hidden")) {
      this.guideTarget.classList.add("hidden");
      gtag("event", "start_swipe");
    }
  }
}
